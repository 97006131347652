<div class="wrapper">
    <div id="main-div">
        <div id="main-button" class="wave open" (click)="toggleIcons()">
            <i [ngClass]="{'fa-comments': !isIconOpen, 'fa-times': isIconOpen}" class="fas"></i>
        </div>
        <a href="tel:{{ telephone }}" class="call-us" *ngIf="isIconOpen"><i style="position: relative; right: 7px;" class="fa fa-phone"></i>{{'CALL_US'|translate}}</a>
        <a *ngIf="isIconOpen" target="_blank" href="whatsapp://tel:{{ telephone }}" class="whatsapp-color"><i class="fa fa-whatsapp"></i></a>
        <a *ngIf="isIconOpen" target="_blank" href="{{ youtube }}" class="youtube-color"><i class="fa fa-youtube"></i></a>
        <a *ngIf="isIconOpen" target="_blank" href="{{ instagram }}" class="messenger-color"><i class="fa fa-instagram"></i></a>
    </div>
</div>
